import { OrganizationRoleResolver } from '@gcv/shared';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ReactComponent as DropdownArrow } from 'assets/images/drop-down-arrow.svg';
import { ReactComponent as NotificationBell } from 'assets/images/notification-bell.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings-icon.svg';
import { ReactComponent as DropdownGrey } from 'assets/images/down-arrow-grey.svg';
import { useComponent } from 'hooks';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { getNotificationStore } from 'stores/NotificationStore';
import { getUserStore } from 'stores/UserStore';
import { AppBar, Badge, Button, Container, ContainerItem, Divider, Icon, Text } from 'ui/atoms';
import { Banner } from 'ui/organisms';
import { NotificationsDrawer } from 'ui/systems';
import ApiAuth0Session, { GCApplicationAccess } from 'api/api-util/api-auth0-session';

interface Props extends Record<string, unknown> {
  toggleNavDrawer: () => void;
}

export const CrbAppBar: React.FC<Props> = useComponent((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobilesm = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = '261px';

  const history = useHistory();

  const dispensaryStore = getCrbDispensaryStore(history);
  const userStore = getUserStore();
  const notificationStore = getNotificationStore();

  const resolver = new OrganizationRoleResolver();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [companyMap, setCompanyMap] = React.useState<{ id: string; name: string }[]>([]);
  const [notificationDrawerOpen, setNotificationDrawerOpen] = React.useState(false);

  const logout = () => {
    history.replace('/logout');
  };

  React.useEffect(() => {
    setCompanyMap(
      Object.values(dispensaryStore.limitedDispensaries)
        .map((d) => {
          return { id: d.crbId, name: d.name };
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    );
  }, [dispensaryStore.dispensaries]);

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const changeOrg = (orgId: string) => {
    dispensaryStore.setCurrentDispensary(orgId);

    notificationStore.stopPolling();
    notificationStore.clearStore();
    notificationStore.getAllUserNotifications(orgId, userStore.user.id);
    notificationStore.startPolling(orgId, userStore.user.id);

    handlePopoverClose();

    // Todo: There is probably a cleaner way of doing this, perhaps within the routing logic

    //if user is currently on an onboarding page, redirect them to dashboard to prevent weird stuff
    if (window.location.href.indexOf('/secure/crb/onboarding') > -1) {
      history.push('/secure/crb/dashboard');
    }
    //if user is currently on users page, but does not have access, redirect them to dashboard
    if (
      window.location.href.indexOf('/secure/crb/users') > -1 &&
      !(
        resolver.userCanDoAction(dispensaryStore.currentDispensary.groups, userStore.user, 'user_add') ||
        resolver.userCanDoAction(dispensaryStore.currentDispensary.groups, userStore.user, 'user_remove')
      )
    ) {
      history.push('/secure/crb/dashboard');
    }
    // If user is currently on Questionnaires Inbox page, but does not have access, redirect them to Dashboard.
    if (
      window.location.href.indexOf('/secure/crb/questionnaires') > -1 &&
      !resolver.userCanDoAction(
        dispensaryStore.currentDispensary.groups,
        userStore.user,
        'questionnaire_complete'
      )
    ) {
      history.push('/secure/crb/dashboard');
    }
    //if user is currently on company profile page, but does not have access, redirect them to dashboard
    if (
      window.location.href.indexOf('/secure/crb/company') > -1 &&
      !resolver.userCanDoAction(
        dispensaryStore.currentDispensary.groups,
        userStore.user,
        'organizational_details_view'
      )
    ) {
      history.push('/secure/crb/dashboard');
    }

    window.localStorage.removeItem('hasDocumentRequirements');
  };

  const switchToPayqwick = () => {
    ApiAuth0Session.selectedApplication = GCApplicationAccess.All;
    history.push('/sso');
  };

  const switchToSupportLogin = () => {
    window.sessionStorage.removeItem('gcvSupportUserOrg');
    window.sessionStorage.removeItem('gcvSupportUserOrg');
    history.push('/login/support');
  };

  /**
   * This is the dropdown that shows when a user clicks on their username in the top-right
   * Contain switch orgs, logout, siwtch to SSO, switch back to support buttons
   */
  const userPopoverOptions = () => {
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Container column dataCy="muo-container" padding={0} width="260px" position="relative">
          <Container dataCy="muo-container-header" padding={0}>
            <ContainerItem dataCy="muo-user-info">
              <Avatar sx={{ mr: 1 }}>
                {userStore.user.firstName.slice(0, 1)}
                {userStore.user.lastName.slice(0, 1)}
              </Avatar>
            </ContainerItem>
            <ContainerItem>
              <Text content={userStore.user.email} dataCy="muo-user-email" type="body2" />
            </ContainerItem>
          </Container>
          <ContainerItem padding={0} width="100%">
            <Divider />
          </ContainerItem>
          <ContainerItem dataCy="muo-org-list" padding={0} width="100%">
            <List>
              {companyMap.map((company) => {
                return (
                  <ListItem
                    button
                    dense
                    key={company.id}
                    divider
                    onClick={() => changeOrg(company.id)}
                    sx={{
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.primary.light,
                        color: (theme) => theme.palette.primary.main,

                        '& .MuiListItemText-root .MuiTypography-root > div': {
                          color: (theme) => `${theme.palette.primary.main} !important`
                        }
                      }
                    }}
                  >
                    <ListItemText
                      primary={
                        <Text
                          sx={{ wordWrap: 'break-word' }}
                          content={`${company.name}`}
                          dataCy="muo-org-list-name"
                        />
                      }
                    />
                    <ListItemSecondaryAction>
                      {company.id === dispensaryStore.currentDispensary.id ? (
                        <Icon type="check_circle" color="primary" dataCy="muo-org-list-icon" />
                      ) : (
                        <Icon type="arrow_forward_ios" color="disabled" dataCy="muo-org-list-icon" />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
            {userStore.user.role === 'gcv_customer_support' && (
              <Box sx={{ width: '100%', position: 'sticky', bottom: '0px', padding: '8px' }}>
                <Button
                  fullWidth
                  color="primary-outlined"
                  label="Switch Orgs"
                  onClick={switchToSupportLogin}
                  variant="text"
                />
              </Box>
            )}
            {userStore.user.pq_user_id && (
              <Box sx={{ width: '100%', position: 'sticky', bottom: '0px', padding: '8px' }}>
                <Button
                  fullWidth
                  color="primary-outlined"
                  label="Switch to PayQwick"
                  onClick={switchToPayqwick}
                  variant="text"
                />
              </Box>
            )}
            <Box sx={{ width: '100%', position: 'sticky', bottom: '0px', padding: '8px' }}>
              <Button fullWidth color="default" label="Log Out" onClick={() => logout()} variant="text" />
            </Box>
          </ContainerItem>
        </Container>
      </Popover>
    );
  };

  if (isMobilesm) {
    return (
      <AppBar>
        <Box
          sx={{
            margin: '11px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <IconButton disableRipple color="inherit" onClick={() => props.toggleNavDrawer()} size="large">
            <Icon dataCy="mobile-menu-icon" type="menu" />
          </IconButton>
          <Link to="/secure/crb/dashboard" data-cy="logo-link">
            <img src="/img/ic-gcv-mobile.svg" alt="Green Check Verified" height={'28px'} width={'52px'} />
          </Link>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ color: (theme) => theme.palette.text.secondary, pr: '23px', maxWidth: '50px' }}>
              <Badge badgeContent={notificationStore.notifications.length} color="primary">
                <NotificationBell
                  onClick={() => setNotificationDrawerOpen(!notificationDrawerOpen)}
                  style={{ cursor: 'pointer' }}
                />
              </Badge>
            </Box>
            <Container nowrap dataCy="mui-dropdown-container" onClick={handlePopoverClick} padding="0">
              <DropdownGrey />
            </Container>
          </Box>
        </Box>

        {userPopoverOptions()}

        <NotificationsDrawer
          drawerOpen={notificationDrawerOpen}
          setDrawerOpen={setNotificationDrawerOpen}
          org={dispensaryStore.currentDispensary}
        />
      </AppBar>
    );
  }

  return (
    <AppBar>
      <Box
        sx={{
          alignItems: 'center',
          borderRight: '1px solid #bdbdbd',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: isMobile ? 'auto' : drawerWidth
        }}
      >
        {isMobile ? (
          <IconButton
            disableRipple
            color="inherit"
            onClick={() => props.toggleNavDrawer()}
            size="large"
            sx={{ mx: 2 }}
          >
            <Icon dataCy="mobile-menu-icon" type="menu" />
          </IconButton>
        ) : (
          <Link to="/secure/crb/dashboard" data-cy="logo-link">
            <img src="/img/logo-2.svg" alt="Green Check Verified" height={'22px'} width={'168px'} />
          </Link>
        )}
      </Box>

      <Banner />
      <Divider vertical />
      <Box sx={{ color: (theme) => theme.palette.text.secondary, pl: 2, pr: 1, maxWidth: '50px' }}>
        <Badge badgeContent={notificationStore.notifications.length} color="primary">
          <NotificationBell
            onClick={() => setNotificationDrawerOpen(!notificationDrawerOpen)}
            style={{ cursor: 'pointer' }}
          />
        </Badge>
      </Box>

      <Box sx={{ color: (theme) => theme.palette.text.secondary, pl: 1, pr: 2 }}>
        <SettingsIcon
          onClick={() => history.push('/secure/crb/users/settings')}
          style={{ cursor: 'pointer' }}
          data-cy="settings-button"
        />
      </Box>

      <Divider vertical />

      <Container
        nowrap
        align="center"
        dataCy="mui-dropdown-container"
        onClick={handlePopoverClick}
        width="275px"
        maxWidth="275px"
      >
        <ContainerItem grow>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <Text
              inline
              sx={{
                wordWrap: 'break-word',
                maxWidth: '200px',
                display: 'inline-block',
                width: '160px',
                whiteSpace: 'nowrap',
                overflow: 'hidden !important',
                textOverflow: 'ellipsis'
              }}
              content={`${dispensaryStore.currentDispensary.name}`}
              dataCy="app-bar-dispensary-name"
            />
          </Box>
        </ContainerItem>
        <ContainerItem>
          <DropdownArrow />
        </ContainerItem>
      </Container>

      {userPopoverOptions()}

      <NotificationsDrawer
        drawerOpen={notificationDrawerOpen}
        setDrawerOpen={setNotificationDrawerOpen}
        org={dispensaryStore.currentDispensary}
      />
    </AppBar>
  );
});
