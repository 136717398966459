import { Icon, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  active: boolean;
  dataCy: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  text: string;
  to: string;
  fontSize?: string;
  height?: string;
}

export const ListItemLink: React.FC<Props> = (props) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement>(function Link(itemProps, ref) {
        return (
          <RouterLink
            {...itemProps}
            to={props.to}
            onClick={() => {
              if (props.onClick) props.onClick();
            }}
            ref={ref}
            role="navigation"
          />
        );
      }),
    [props.to]
  );

  const renderAnchor = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement>(function Link(itemProps, ref) {
        return (
          <RouterLink
            {...itemProps}
            to={props.to}
            onClick={(e) => {
              e.preventDefault();
              window.open(props.to, '_blank');
            }}
            ref={ref}
            role="navigation"
          />
        );
      }),
    [props.to]
  );

  return (
    <ListItem
      button
      disableGutters
      disableRipple
      component={props.to.includes('http') ? renderAnchor : renderLink}
      data-cy={props.dataCy}
      sx={{
        backgroundColor: props.active ? 'rgba(3, 166, 91, 0.07)' : '',
        height: props.height ? props.height : '48px',
        '& .MuiListItemIcon-root': {
          color: props.active ? '#03A65B' : 'text.primary'
        },
        '& .MuiTypography-root': {
          color: props.active ? '#03A65B' : 'text.primary',
          fontWeight: 700,
          fontSize: props.fontSize ? props.fontSize : '14px'
        },
        '&:before': {
          backgroundColor: props.active ? '#03A65B' : '',
          content: "''",
          height: '40px',
          width: '3px',
          marginRight: '39px'
        },
        '&:hover': {
          backgroundColor: props.active ? 'rgba(3, 166, 91, 0.07)' : 'transparent',
          '& .MuiListItemIcon-root': {
            color: 'primary.main'
          },
          '& .MuiTypography-root': {
            color: 'primary.main'
          }
        }
      }}
    >
      {props.icon && <ListItemIcon sx={{ ml: 0, mr: '18px' }}>{props.icon}</ListItemIcon>}
      <ListItemText primary={props.text} />
    </ListItem>
  );
};
