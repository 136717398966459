import { IANATimezones } from '@gcv/shared';
import { Box, SelectChangeEvent } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { FieldValues, useForm } from 'react-hook-form';
import { useMediaQuery, useTheme } from '@mui/material';
import { getAppViewStateStore, TimePeriod } from 'stores/AppViewStateStore';
import { Icon, InputSelect, Label } from 'ui';
import palette from 'ui/theme/palette';
import { getDateRange } from '../../../util/dateRange.util';
import { DateTimeHelpers } from '../../../util/dateTime.util';
import { actions } from './actions';
import { model } from './model';

interface Props {
  label?: string;
  background?: 'white' | 'grey' | 'dark-grey';
  emitData: (data: TimePeriod) => void;
  timezone: IANATimezones;
  fullWidth?: boolean;
  verticalLabel?: boolean;
  includeAllOption?: boolean;
  defaultValue?: string;
  dataCy?: string;
  centerLabel?: boolean;
  calendarMargin?: string;
  calendarWidth?: string;
  headerComponent?: boolean;
  limitTimePeriodBy90Days?: boolean;
  type?: string;
}

export const TimePeriodDropdown = (props: Props) => {
  const appViewStore = getAppViewStateStore();

  const [calendarWidth, setCalenderWidth] = useState(false);
  const timePeriodOptions = props.limitTimePeriodBy90Days
    ? [
        {
          value: 'today',
          label: 'Today',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'yesterday',
          label: 'Yesterday',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'thisWeek',
          label: 'This Week',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'thisMonth',
          label: 'This Month',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'thisQuarter',
          label: 'This Quarter',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'lastWeek',
          label: 'Last Week',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'lastMonth',
          label: 'Last Month',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'lastQuarter',
          label: 'Last Quarter',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'last7Days',
          label: 'Last 7 Days',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'last30Days',
          label: 'Last 30 Days',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'custom',
          label: model.customTimeRangeOptionLabel,
          action: () => {
            setCalenderWidth(true);
            setCalendarOpen(!calendarOpen);
          }
        }
      ]
    : [
        {
          value: 'today',
          label: 'Today',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'yesterday',
          label: 'Yesterday',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'thisWeek',
          label: 'This Week',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'thisMonth',
          label: 'This Month',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'thisQuarter',
          label: 'This Quarter',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'thisYear',
          label: 'This Year',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'lastWeek',
          label: 'Last Week',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'lastMonth',
          label: 'Last Month',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'lastQuarter',
          label: 'Last Quarter',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'lastYear',
          label: 'Last Year',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'last7Days',
          label: 'Last 7 Days',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'last30Days',
          label: 'Last 30 Days',
          action: () => {
            setCalenderWidth(false);
          }
        },
        {
          value: 'custom',
          label: model.customTimeRangeOptionLabel,
          action: () => {
            setCalenderWidth(true);
            setCalendarOpen(!calendarOpen);
          }
        }
      ];

  const [options, setOptions] = useState(
    props.includeAllOption ? [{ value: 'all', label: 'All' }, ...timePeriodOptions] : timePeriodOptions
  );

  //check if there is a state stored in the appViewStateStore
  const previousValue =
    props.type && appViewStore.getTimePeriod(props.type)
      ? appViewStore.getTimePeriod(props.type)!.value
      : null;

  const defaultValue = previousValue
    ? previousValue
    : props.defaultValue && options.find((o) => o.value === props.defaultValue)
    ? props.defaultValue
    : 'custom';
  const form = useForm({
    defaultValues: { timePeriod: defaultValue } as FieldValues
  });
  const calendarRef = useRef<any>();
  const [calendarValues, setCalendarValues] = useState<Date[]>([]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const emitData = (selection: string) => {
    const timeRange = getDateRange(selection, props.timezone);
    props.emitData({
      timeRange: timeRange,
      dateRange: {
        start: DateTime.fromISO(timeRange.start, { zone: props.timezone }).toFormat('yyyy-MM-dd'),
        end: DateTime.fromISO(timeRange.end, { zone: props.timezone }).toFormat('yyyy-MM-dd')
      },
      value: selection
    });
  };

  //check for clicks outside of calendar modal to close calendar
  useEffect(() => {
    if (calendarOpen) {
      document.addEventListener('mousedown', handleCalendarBlur);
      return () => {
        document.removeEventListener('mousedown', handleCalendarBlur);
      };
    }
  }, [calendarOpen]);

  useEffect(() => {
    if (form.getValues('timePeriod') === 'custom') {
      setCalenderWidth(true);
    } else {
      setCalenderWidth(false);
    }
    if (defaultValue !== undefined) {
      if (defaultValue === 'custom') {
        const existingCustomOption = options.find((o) => o.value === 'custom');
        emitData(existingCustomOption?.label || 'last30Days');
      } else {
        emitData(defaultValue as string);
      }
    }
  }, []);

  //update parent component with select date range
  const onChange = (e: SelectChangeEvent<any>) => {
    const data = e.target.value;
    if (data !== 'custom') {
      emitData(data as string);
    }
  };

  //update parent component with select custom date range
  const selectCustom = (values: Date[]) => {
    setCalendarOpen(false);

    // Do not use DateTimeHelpers here because we want raw selected value without timezone manipulation
    const selectedCustomValue = `${values[0].toLocaleDateString('en-us', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })} - ${values[1].toLocaleDateString('en-us', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })}`;

    actions.setCustomTimeRangeOptionLabel(selectedCustomValue);

    const newCustomOptions = [...options];
    newCustomOptions[newCustomOptions.length - 1].label = selectedCustomValue;
    setOptions(newCustomOptions);

    props.emitData({
      dateRange: {
        start: DateTime.fromJSDate(values[0]).toFormat('yyyy-MM-dd'),
        end: DateTime.fromJSDate(values[1]).toFormat('yyyy-MM-dd')
      },
      timeRange: {
        start: DateTimeHelpers.formatJsDateToISO(values[0], props.timezone),
        end: DateTimeHelpers.formatJsDateToISO(values[1], props.timezone)
      },
      value: 'custom'
    });
  };

  //update label on calendar click
  const handleCalendarClick = (date: Date) => {
    const calendarVals = calendarValues.length == 2 ? [] : [...calendarValues];
    if (props.limitTimePeriodBy90Days && calendarValues.length % 2 === 0) {
      // second date should be selected within 90 day window
      // including the end date, 89+1=90 days
      const dateMin = new Date(date);
      dateMin.setDate(dateMin.getDate() - 89);
      const dateMax = new Date(date);
      dateMax.setDate(dateMax.getDate() + 89);
      setMinDate(dateMin);
      setMaxDate(dateMax);
    } else {
      setMinDate(undefined);
      setMaxDate(undefined);
    }
    if (calendarVals.length <= 1) {
      setCalendarValues([...calendarVals, date]);
      const newCustomOptions = [...options];
      const selectedCustomValue = `${DateTimeHelpers.formatJsDateToTableDate(date, props.timezone)} - `;
      newCustomOptions[newCustomOptions.length - 1].label = selectedCustomValue;
      setOptions(newCustomOptions);
    }
  };

  //close calendar on blur, if no new option selected, maintain existing
  const handleCalendarBlur = (e: any) => {
    if (calendarRef && calendarRef?.current) {
      if (!calendarRef.current.contains(e.target)) {
        const existingCustomOption = options.find((o) => o.value === 'custom');
        if (
          existingCustomOption &&
          existingCustomOption?.label !== 'Custom' &&
          existingCustomOption?.label.split('-')[1]?.trim()
        ) {
          const optionStartDate = existingCustomOption.label.split('-')[0].trim();
          const optionEndDate = existingCustomOption.label.split('-')[1].trim();

          const startDate = DateTimeHelpers.formatJsDateToISO(new Date(optionStartDate), props.timezone);
          const endDate = DateTime.fromISO(
            DateTimeHelpers.formatJsDateToISO(new Date(optionEndDate), props.timezone)
          )
            .endOf('day')
            .toISO();
          props.emitData({
            dateRange: {
              start: DateTime.fromJSDate(new Date(optionStartDate)).toFormat('yyyy-MM-dd'),
              end: DateTime.fromJSDate(new Date(optionEndDate)).toFormat('yyyy-MM-dd')
            },
            timeRange: {
              start: startDate,
              end: endDate
            },
            value: 'custom'
          });
        }
        setCalendarOpen(false);
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: isMobile ? 'block' : 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center'
        }}
        data-cy={props.dataCy}
      >
        <div
          data-cy={props.dataCy}
          style={{
            width: props.headerComponent
              ? !calendarWidth
                ? '165px'
                : '215px'
              : props.fullWidth
              ? '100%'
              : '260px'
          }}
        >
          <InputSelect
            headerComponent={props.headerComponent ? true : false}
            background={props.background}
            label={props.label ?? ''}
            noLabel={!props.verticalLabel}
            name="timePeriod"
            options={options}
            additionalOnChange={onChange}
            {...form}
          />
        </div>
      </div>

      {calendarOpen ? (
        <div style={{ position: 'relative' }}>
          <Box
            ref={calendarRef}
            sx={{
              width: props.headerComponent ? '210px' : '250px',
              '& .react-calendar': {
                marginTop: props.calendarMargin ? props.calendarMargin : `10px`,
                marginLeft: props.calendarMargin ? props.calendarMargin : isMobile ? '1px' : '0',
                position: `absolute`,
                width: props.calendarWidth ? props.calendarWidth : `275px`,
                maxWidth: `100%`,
                background: `white`,
                lineHeight: ` 1.125em`,
                zIndex: `100`,
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '6px',
                fontFamily: `lato, Helvetica, sans-serif`,
                padding: '8px',
                color: `${palette.text.primary}`,

                '& button': {
                  textAlign: `center`,
                  padding: `0.75em 0.5em`,
                  background: `none`,
                  cursor: `pointer`,
                  border: `none`,

                  '&: hover': {
                    backgroundColor: `${palette.background}`
                  },

                  '& focus': {
                    outline: `none`
                  }
                },
                '& abbr': {
                  textDecoration: 'none'
                },

                '& .react-calendar__month-view__weekdays': {
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontSize: '0.75em',
                  color: `${palette.text.primary}`
                },
                '& .react-calendar__month-view__weekdays__weekday': {
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '18px',
                  paddingBottom: '5px',
                  textDecoration: 'none',
                  color: `${palette.text.primary}`
                },

                '& .react-calendar__navigation__label__labelText': {
                  fontWeight: 'bold',
                  color: `${palette.text.primary}`,
                  fontSize: '14px',
                  margin: '10px'
                },

                '& .react-calendar__month-view__days__day--weekend': {
                  color: `${palette.text.secondary}`
                },

                '& .react-calendar__month-view__days__day--neighboringMonth': {
                  color: `${palette.text.secondary}`
                },
                '& .react-calendar__month-view__days__day': {
                  color: `${palette.text.secondary}`
                },
                '& .react-calendar__tile': {
                  fontFamily: `lato, Helvetica, sans-serif`,
                  color: `${palette.text.secondary}`,
                  fontSize: '12px',
                  lineHeight: '12px',
                  border: `1px solid white`
                },
                '& .react-calendar__tile:disabled': {
                  fontFamily: `lato, Helvetica, sans-serif`,
                  color: `${palette.error.main}`,
                  fontSize: '12px',
                  lineHeight: '12px',
                  cursor: 'not-allowed',
                  backgroundColor: `${palette.text.disabled}`,
                  border: 'none'
                },
                '& .react-calendar__tile--range': {
                  backgroundColor: `${palette.primary.light}`
                },
                '& .react-calendar__tile--hover': {
                  border: `1px solid ${palette.primary.light}`,
                  backgroundColor: `${palette.primary.light}`,
                  borderRadius: '0px'
                },
                '& .react-calendar__tile--now': {
                  border: `1px solid ${palette.primary.main}`,
                  color: `${palette.primary.main}`,
                  boxSizing: 'border-box'
                },
                '& .react-calendar__tile--hoverStart': {
                  backgroundColor: `${palette.primary.main}`,
                  color: 'white'
                },
                '& .react-calendar__tile--hoverEnd': {
                  backgroundColor: `${palette.primary.main}`,
                  color: 'white'
                },
                '& .react-calendar__tile--rangeStart': {
                  backgroundColor: `${palette.primary.main}`,
                  color: 'white'
                },
                '& .react-calendar__tile--rangeEnd': {
                  backgroundColor: `${palette.primary.main}`,
                  color: 'white'
                }
              }
            }}
          >
            <Calendar
              formatShortWeekday={(locale, date) => {
                const days = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'S'];
                const d = new Date(date);
                const dayName = days[d.getDay()];
                return dayName;
              }}
              nextLabel={
                <Icon
                  type="keyboard_arrow_right_rounded"
                  color="action"
                  size={20}
                  onClick={() => {
                    const a = 1;
                  }}
                />
              }
              prevLabel={
                <Icon
                  type="keyboard_arrow_left_rounded"
                  color="action"
                  size={20}
                  onClick={() => {
                    const a = 1;
                  }}
                />
              }
              next2Label={<div></div>}
              prev2Label={<div></div>}
              selectRange={true}
              onChange={selectCustom}
              calendarType={'US'}
              onClickDay={handleCalendarClick}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Box>
        </div>
      ) : null}
    </>
  );
};
