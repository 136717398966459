import { CommentIdComponents, CommentType } from '@gcv/shared';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Container,
  ContainerItem,
  Hyperlink,
  MenuOption,
  TabContent,
  TabNavigation,
  TabNavigationDelegate,
  TabbedPageHeader,
  Text
} from 'ui';
import { theme } from 'ui/theme';
import useQueryString from 'util/queryString.util';

export interface PageTab {
  id: string;
  title: string;
  component: React.FC;
  notificationCount?: number;
  description?: string;
  topActions?: React.ReactNode;
  actions?: React.ReactNode;
  topActionWidth?: string;
  disabled?: boolean;
  notificationCountColor?: 'error' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
}

interface Props {
  tabs: PageTab[];
  title: string;
  dataCy: string;
  additionalTitle?: string;
  additionalTitleIcon?: React.ReactNode;
  subTitle?: string;
  titleIcon?: React.ReactNode;
  titleMaxWidth?: string;
  settingsOptions?: MenuOption[];
  onBack?: { path: string };
  onBackCallback?: () => unknown | boolean;
  onLoadCallback?: () => void;
  onTabChange?: (currentTab: string) => void;
  tabNavigationDelegate?: TabNavigationDelegate;
  zeroState?: React.ReactNode;
  bottomActions?: React.ReactNode;
  noticeBar?: boolean;
  commentsBadgeProps?: {
    idComponents: CommentIdComponents;
    title: string;
    type: CommentType;
  };
  descriptionHeader?: boolean;
  onAdditionalTitleClick?: () => void;
  enableAdditionalTitleHover?: boolean;
  titleAlign?: 'flex-start' | 'flex-end' | 'center';
  titlePadding?: string;
  stickyTabs?: boolean;
}

export const TabbedPage: React.FC<Props> = (props) => {
  const [currentTab, setCurrentTab] = useQueryString('tab', '');
  const [showFullDescription, setShowDescription] = React.useState(false);
  const getDefaultTabFromQueryString = (): number => {
    if (currentTab) {
      const tab = props.tabs.find((s) => s.id === currentTab);
      if (tab && props.tabs.indexOf(tab) !== undefined) {
        return props.tabs.indexOf(tab);
      }
    }
    return 0;
  };
  const [activeTab, setActiveTab] = useState(0);
  const [tabStyles, setTabStyles] = useState<React.CSSProperties | null>(null);

  const mainHeight = props.bottomActions || props.noticeBar ? 'calc(100% - 85px)' : '100%';
  let mainElement: Element | null;

  useEffect(() => {
    if (props.onLoadCallback) {
      props.onLoadCallback();
    }

    if (props.stickyTabs) {
      const mainContentElement = document.querySelector('#main-content > div');

      if (mainContentElement) {
        mainContentElement.addEventListener('scroll', function () {
          stickyTabs();
        });
        mainElement = mainContentElement;
      }
    }
  }, []);

  useEffect(() => {
    setActiveTab(getDefaultTabFromQueryString());
  }, [props.tabs]);

  const changeTab = (newValue: number) => {
    setActiveTab(newValue);
    setCurrentTab(props.tabs[newValue].id);

    if (props.onTabChange) {
      props.onTabChange(props.tabs[newValue].title);
    }
  };

  const stickyTabs = () => {
    // 73px is top navbar height so we want to stick the tabs to
    // the top of the page when the user scrolls past that
    if (mainElement && mainElement.scrollTop > 73) {
      setTabStyles({
        position: 'fixed',
        top: '73px',
        zIndex: '1000',
        backgroundColor: theme.palette.background.default,
        width: '100%',
        paddingRight: '24px'
      });
    } else {
      setTabStyles({
        width: '100%',
        paddingRight: '24px'
      });
    }
  };

  return (
    <>
      <div style={{ height: mainHeight, overflow: 'auto' }}>
        <Container column dataCy={props.dataCy} width="100%" padding="11px 12px 11px 26px">
          <TabbedPageHeader
            title={props.title}
            additionalTitle={props.additionalTitle}
            additionalTitleIcon={props.additionalTitleIcon}
            subTitle={props.subTitle}
            titleIcon={props.titleIcon}
            titleMaxWidth={props.titleMaxWidth}
            settingsOptions={props.settingsOptions}
            onBack={props.onBack}
            onBackCallback={props.onBackCallback}
            topActions={!props.zeroState ? props.tabs[activeTab]?.topActions : undefined}
            commentsBadgeProps={props.commentsBadgeProps}
            topActionWidth={props.tabs[activeTab]?.topActionWidth}
            onAdditionalTitleClick={props.onAdditionalTitleClick}
            enableAdditionalTitleHover={props.enableAdditionalTitleHover}
            titleAlign={props.titleAlign}
            titlePadding={props.titlePadding}
          />

          {!props.zeroState && props.tabs[activeTab]?.description && (
            <ContainerItem padding="0 8px 8px 8px" width="100%" column>
              <Text
                content="Description"
                type="body3"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 700,
                  paddingBottom: '10px',
                  display: props.descriptionHeader ? 'block' : 'none'
                }}
              />
              <Text
                type="body2"
                content={
                  props.tabs[activeTab].description!.length > 106 && !showFullDescription
                    ? props.tabs[activeTab].description!.substring(0, 105) + '...'
                    : props.tabs[activeTab].description!
                }
                dataCy="page-description"
                sx={{ color: '#000', paddingBottom: '13.5px', display: 'inline' }}
              />

              {props.tabs[activeTab].description!.length > 106 && (
                <Hyperlink
                  label={showFullDescription ? 'See less' : 'See more'}
                  style={{
                    display: 'inline',
                    marginLeft: '4px',
                    fontSize: '14px',
                    lineHeight: '20px'
                  }}
                  onClick={() => {
                    setShowDescription(!showFullDescription);
                  }}
                />
              )}
            </ContainerItem>
          )}

          {!props.zeroState && props.tabs[activeTab]?.actions && (
            <ContainerItem dataCy="page-actions" padding={0}>
              <Box>{props.tabs[activeTab]?.actions}</Box>
            </ContainerItem>
          )}

          {!props.zeroState ? (
            <Box sx={tabStyles}>
              <TabNavigation
                activeTab={activeTab}
                tabs={props.tabs}
                changeTab={changeTab}
                delegate={props.tabNavigationDelegate}
              />
            </Box>
          ) : null}

          <TabContent activeTab={activeTab} tabs={props.tabs} zeroState={props.zeroState} />
        </Container>
      </div>
      {props.bottomActions && (
        <Box
          sx={{
            borderTop: '1px solid',
            borderColor: 'border',
            bottom: 0,
            height: '85px',
            position: 'fixed',
            width: '100%'
          }}
        >
          <Container dataCy="page-bottom-actions">
            <ContainerItem>{props.bottomActions}</ContainerItem>
          </Container>
        </Box>
      )}
    </>
  );
};
