import { MainFeatures, OrganizationRoleResolver } from '@gcv/shared';
import { Box, List } from '@mui/material';
import { ReactComponent as AccountsIcon } from 'assets/images/navigation-icons/accounts.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/navigation-icons/dashboard.svg';
import { ReactComponent as DepositsIcon } from 'assets/images/navigation-icons/deposits.svg';
import { ReactComponent as FincenIcon } from 'assets/images/navigation-icons/fincen.svg';
import { ReactComponent as DataIcon } from 'assets/images/navigation-icons/ic-data-round.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/navigation-icons/ic-settings.svg';
import { ReactComponent as MonitoringIcon } from 'assets/images/navigation-icons/monitoring.svg';
import { ReactComponent as ReportsIcon } from 'assets/images/navigation-icons/reports.svg';
import { ReactComponent as SupportIcon } from 'assets/images/navigation-icons/support.svg';
import { ReactComponent as TasksIcon } from 'assets/images/navigation-icons/tasks.svg';
import { ReactComponent as UsersIcon } from 'assets/images/navigation-icons/users.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { getFiBankStore } from 'stores/FiBankStore';
import { getUserStore } from 'stores/UserStore';
import { Divider, ListItemLink } from 'ui/atoms';
import { Drawer } from 'ui/molecules';
import { orgFeatureEnabled } from 'util/org.util';

interface Props {
  openNavDrawer: boolean;
  permanent: boolean;
  setOpenNavMenu: (isOpen: boolean) => void;
}

export const FiNavDrawer: React.FC<Props> = (props) => {
  const { tasksAssignments } = useFlags();
  const bankStore = getFiBankStore();
  const userStore = getUserStore();
  const resolver = new OrganizationRoleResolver();
  const isFincenFeatureEnabled =
    orgFeatureEnabled(bankStore.bank, MainFeatures.FinCEN, 'CTR') ||
    orgFeatureEnabled(bankStore.bank, MainFeatures.FinCEN, 'SAR');

  const [activeLink, setActiveLink] = React.useState('');

  const userCanViewFincen =
    isFincenFeatureEnabled &&
    resolver.userCanDoAction(bankStore.bank.groups, userStore.user, 'fincen_report_view');
  const userCanViewMonitoring = resolver.userCanDoAction(
    bankStore.bank.groups,
    userStore.user,
    'account_review_view'
  );
  const userCanViewData = resolver.userCanDoAction(bankStore.bank.groups, userStore.user, 'data_view');
  const userCanViewUsers = resolver.userCanDoAction(bankStore.bank.groups, userStore.user, 'user_add');
  const isBankExaminer = resolver.userHasRole(bankStore.bank.groups, userStore.user, 'bank_examiner');
  const userCanViewDeposits = orgFeatureEnabled(bankStore.bank, MainFeatures.Deposits);

  React.useEffect(() => {
    if (window.location.href.indexOf('/secure/fi/dashboard') > -1) {
      setActiveLink('dashboard');
    } else if (window.location.href.indexOf('/secure/fi/data') > -1) {
      setActiveLink('data');
    } else if (window.location.href.indexOf('/secure/fi/deposits') > -1) {
      setActiveLink('deposits');
    } else if (window.location.href.indexOf('/secure/fi/accounts') > -1) {
      setActiveLink('accounts');
    } else if (window.location.href.indexOf('/secure/fi/fincen') > -1) {
      setActiveLink('fincen');
    } else if (window.location.href.indexOf('/secure/fi/reports') > -1) {
      setActiveLink('reports');
    } else if (window.location.href.indexOf('/secure/fi/monitoring') > -1) {
      setActiveLink('monitoring');
    } else if (window.location.href.indexOf('/secure/fi/tasks') > -1) {
      setActiveLink('tasks');
    } else if (window.location.href.indexOf('/secure/fi/settings') > -1) {
      setActiveLink('settings');
    } else if (window.location.href.indexOf('/secure/fi/users') > -1) {
      setActiveLink('users');
    }
  }, []);

  return (
    <Drawer
      anchor="left"
      hideBackdrop={props.permanent}
      onClose={() => props.setOpenNavMenu(false)}
      open={props.openNavDrawer}
      permanent={props.permanent}
      dataCy={props.permanent ? 'nav-drawer' : 'mobile-nav-drawer'}
    >
      <Box
        sx={{
          alignItems: 'center',
          borderRight: '1px solid #bdbdbd',
          display: 'flex',
          height: '73px',
          minHeight: '73px',
          justifyContent: 'center',
          width: '261px'
        }}
      >
        <Link to="/secure/fi/dashboard" data-cy="logo-link">
          <img src="/img/logo-2.svg" alt="Green Check Verified" height={'22px'} width={'168px'} />
        </Link>
      </Box>
      <Divider />
      <List component="nav">
        <ListItemLink
          active={activeLink === 'dashboard'}
          dataCy="dashboard-nav-link"
          icon={<DashboardIcon />}
          onClick={() => setActiveLink('dashboard')}
          text="Dashboard"
          to="/secure/fi/dashboard"
        />

        {userCanViewDeposits && (
          <ListItemLink
            active={activeLink === 'deposits'}
            dataCy="deposits-nav-link"
            icon={<DepositsIcon />}
            onClick={() => setActiveLink('deposits')}
            text="Deposits"
            to="/secure/fi/deposits"
          />
        )}

        <ListItemLink
          active={activeLink === 'accounts'}
          dataCy="accounts-nav-link"
          icon={<AccountsIcon />}
          onClick={() => setActiveLink('accounts')}
          text="Accounts"
          to="/secure/fi/accounts"
        />

        {userCanViewFincen && (
          <ListItemLink
            active={activeLink === 'fincen'}
            dataCy="fincen-nav-link"
            icon={<FincenIcon />}
            onClick={() => setActiveLink('fincen')}
            text="FinCEN"
            to="/secure/fi/fincen"
          />
        )}

        {!isBankExaminer && (
          <ListItemLink
            active={activeLink === 'reports'}
            dataCy="reports-nav-link"
            icon={<ReportsIcon />}
            onClick={() => setActiveLink('reports')}
            text="Reports"
            to="/secure/fi/reports"
          />
        )}

        {userCanViewMonitoring && (
          <ListItemLink
            active={activeLink === 'monitoring'}
            dataCy="monitoring-nav-link"
            icon={<MonitoringIcon />}
            onClick={() => setActiveLink('monitoring')}
            text="Monitoring"
            to="/secure/fi/monitoring"
          />
        )}

        {(userCanViewData || isBankExaminer) && (
          <ListItemLink
            active={activeLink === 'data'}
            dataCy="data-nav-link"
            icon={<DataIcon />}
            onClick={() => setActiveLink('data')}
            text="Data"
            to="/secure/fi/data"
          />
        )}

        {tasksAssignments && !isBankExaminer && (
          <ListItemLink
            active={activeLink === 'tasks'}
            dataCy="tasks-nav-link"
            icon={<TasksIcon />}
            onClick={() => setActiveLink('tasks')}
            text="Tasks"
            to="/secure/fi/tasks"
          />
        )}

        <ListItemLink
          active={activeLink === 'settings'}
          dataCy="settings-nav-link"
          icon={<SettingsIcon />}
          onClick={() => setActiveLink('settings')}
          text="Settings"
          to="/secure/fi/settings"
        />

        {userCanViewUsers && (
          <ListItemLink
            active={activeLink === 'users'}
            dataCy="users-nav-link"
            icon={<UsersIcon />}
            onClick={() => setActiveLink('susersettings')}
            text="Users"
            to="/secure/fi/users"
          />
        )}

        <ListItemLink
          active={false}
          dataCy="support-nav-link"
          icon={<SupportIcon />}
          text="Support"
          to="https://support.greencheckverified.com"
        />
      </List>
    </Drawer>
  );
};
