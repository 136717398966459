import {
  Action,
  Group,
  OrganizationRoleResolver,
  Role,
  User,
  isBankUser,
  isDispensaryUser,
  isGcvUser
} from '@gcv/shared';
import * as H from 'history';
import { getCrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { getFiBankStore } from 'stores/FiBankStore';
import { getFiDispensaryStore } from 'stores/FiDispensaryStore';
import { getFiTemplateStore } from 'stores/FiTemplateStore';
import { getGcvAdminStore } from 'stores/GcvAdminStore';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { UserStore } from 'stores/UserStore';
import { deleteRedirectUrl, getRedirectUrl } from 'util/auth0-storage.util';

/**
 * Redirect user to the desired path.
 *
 * @param pathNameGuard substring that must be found in current path.
 * @param defaultPathName path to use when none is stored.
 * @param history React history object.
 */
const redirect = (pathNameGuard: string, defaultPathName: string, history: H.History): void => {
  const storedPathName = getRedirectUrl(true);

  if (storedPathName?.includes(pathNameGuard)) {
    history.push(storedPathName);
    deleteRedirectUrl();
  } else {
    const pathName = history.location.pathname.includes(pathNameGuard)
      ? history.location.pathname + history.location.search
      : defaultPathName;

    history.push(pathName);
  }
};

export const userHasRole = (orgGroups: Group[], user: User, roles?: Role[]): boolean => {
  if (user.role === 'gcv_customer_support') {
    return true;
  }

  const roleResolver: OrganizationRoleResolver = new OrganizationRoleResolver();
  let hasRole = true;

  if (roles) {
    roles.forEach((role) => {
      hasRole = hasRole && roleResolver.userHasRole(orgGroups, user, role);
    });
  }

  return hasRole;
};

export const userCanDoAction = (orgGroups: Group[], user: User, action?: Action): boolean => {
  if (user.role === 'gcv_customer_support') {
    return true;
  }

  const roleResolver: OrganizationRoleResolver = new OrganizationRoleResolver();
  const hasAction = action ? roleResolver.userCanDoAction(orgGroups, user, action) : true;

  return hasAction;
};

export const initializeLoggedInUser = async (
  history: H.History,
  userStore: UserStore,
  setIsLoading: (loading: boolean) => void
) => {
  try {
    if (!userStore.isLoaded || userStore.user.invitation_status === 'archived') {
      return;
    }

    if (userStore.user.role === 'gcv_customer_support') {
      setIsLoading(false);
      history.push('/login/support');
      return;
    }

    const role = getUserType(userStore.user);

    switch (role) {
      case 'bank': {
        await initFi(history, userStore, setIsLoading);
        break;
      }
      case 'dispensary': {
        // Todo remove the empty array when we deprecate lendica org IDs. This is okay right now because we aren't even using it in production
        await initCrb(history, userStore, setIsLoading, []);
        break;
      }
      case 'gcv': {
        await initGcv(history, userStore, setIsLoading);
        break;
      }
    }
  } catch (e) {
    setIsLoading(false);
    getSnackbarStore().showErrorSnackbarMessage(
      'Something went wrong! Please contact support if the problem persists.'
    );
    history.push('/error');
  }
};

export const getUserType = (user: User): 'bank' | 'dispensary' | 'gcv' => {
  if (isGcvUser(user)) {
    if (user.role === 'gcv_customer_support') {
      return user.companies[0].companyType;
    } else {
      return 'gcv';
    }
  } else if (isBankUser(user)) {
    return 'bank';
  } else if (isDispensaryUser(user)) {
    return 'dispensary';
  } else {
    throw new Error(`User ${user.id} type is not defined: ${user.role}`);
  }
};

export const initGcv = async (
  history: H.History,
  userStore: UserStore,
  setIsLoading: (loading: boolean) => void
) => {
  try {
    setIsLoading(true);
    const gcvAdminStore = getGcvAdminStore();
    await gcvAdminStore.loadStore();
    redirect('/secure/gcv/', '/secure/gcv/dashboard', history);
  } catch (e) {
    throw e;
  } finally {
    setIsLoading(false);
  }
};

export const initFi = async (
  history: H.History,
  userStore: UserStore,
  setIsLoading: (loading: boolean) => void
) => {
  try {
    setIsLoading(true);
    const bankStore = getFiBankStore();
    const bankDispensaryStore = getFiDispensaryStore();
    const bankTemplateStore = getFiTemplateStore();

    await bankStore.loadBank(userStore.user.companies[0].id);

    await Promise.all([bankTemplateStore.loadTemplates(), bankDispensaryStore.loadAllOrganizations()]);

    redirect('/secure/fi/', '/secure/fi/dashboard', history);
  } catch (e) {
    throw e;
  } finally {
    setIsLoading(false);
  }
};

export const initCrb = async (
  history: H.History,
  userStore: UserStore,
  setIsLoading: (loading: boolean) => void,
  lendicaOrgIds: string[]
) => {
  try {
    setIsLoading(true);
    const dispensaryStore = getCrbDispensaryStore(history);
    const user = userStore.user;

    if (!user.companies.length && user.marketing_user_in_progress) {
      redirect('/secure/crb/', '/secure/crb/welcome', history);
      return;
    }

    await dispensaryStore.loadDispensaries(
      user.companies.map(({ id }) => id),
      lendicaOrgIds,
      user.id
    );

    if (dispensaryStore.isLoaded && dispensaryStore.invitedDispensaries.length !== 0) {
      history.push('/secure/crb/connect-org');
    }

    //check local storage to see if MUO user had a previous selected dispensary
    const previousSelectedDispensary = window.sessionStorage.getItem('currentDispensaryId');
    const isPreviouslySelectedDispensary = Boolean(
      previousSelectedDispensary && user.companies.some(({ id }) => id === previousSelectedDispensary)
    );
    const currentDispensary = isPreviouslySelectedDispensary
      ? previousSelectedDispensary
      : user.companies[0].id;

    await dispensaryStore.setCurrentDispensary(currentDispensary ?? '');

    if (!user.termsOfService && user.initial_user && user.role !== 'gcv_customer_support') {
      history.push('/tos');
    } else {
      redirect('/secure/crb/', '/secure/crb/dashboard', history);
    }
  } catch (e) {
    throw e;
  } finally {
    setIsLoading(false);
  }
};
