import { createHash } from 'crypto';
import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { FilterListChild } from 'ui';

export interface TimeRange {
  start: string;
  end: string;
}
export interface TimePeriod {
  dateRange: TimeRange;
  timeRange: TimeRange;
  value: string;
}

export interface PageFilters extends TimePeriod {
  selectedFilters: FilterListChild[];
  searchTerm: string;
}

const hashTimePeriod = (period: TimePeriod) => {
  const serialize = (p: TimePeriod) => {
    const data: string[] = [];
    data.push(p.dateRange.start);
    data.push(p.dateRange.end);
    data.push(p.timeRange.start);
    data.push(p.timeRange.end);
    data.push(p.value);
    return data;
  };

  const hash = createHash('sha256');
  const data = serialize(period);
  data.forEach((s) => hash.update(s));

  return hash.digest('hex');
};

export const isEqualTimePeriod = (first: TimePeriod, second: TimePeriod) => {
  return hashTimePeriod(first) === hashTimePeriod(second);
};

@injectable()
export class AppViewStateStore {
  dashboardTimePeriod: TimePeriod = {
    dateRange: { start: '', end: '' },
    timeRange: { start: '', end: '' },
    value: ''
  };
  accountActivityTimePeriod: TimePeriod = {
    dateRange: { start: '', end: '' },
    timeRange: { start: '', end: '' },
    value: ''
  };
  monitoringTimePeriod: TimePeriod = {
    dateRange: { start: '', end: '' },
    timeRange: { start: '', end: '' },
    value: ''
  };
  transactionDataTimePeriod: TimePeriod = {
    dateRange: { start: '', end: '' },
    timeRange: { start: '', end: '' },
    value: ''
  };
  tasksFilters: PageFilters = {
    dateRange: { start: '', end: '' },
    timeRange: { start: '', end: '' },
    searchTerm: '',
    selectedFilters: [],
    value: ''
  };

  accountRelationshipManagerFilters: {
    selectedAccountFilters: FilterListChild[];
  } = {
    selectedAccountFilters: []
  };

  archivedAccountRelationshipManagerFilters: {
    selectedAccountFilters: FilterListChild[];
  } = {
    selectedAccountFilters: []
  };

  connectedOrg = false;

  constructor() {
    makeAutoObservable(this);
  }

  setConnectedOrg = action((value: boolean) => {
    this.connectedOrg = value;
  });

  setTimePeriod = action(
    (data: TimePeriod, type: 'dashboard' | 'account-activity' | 'monitoring' | 'data' | 'tasks') => {
      switch (type) {
        case 'dashboard': {
          runInAction(() => {
            this.dashboardTimePeriod = data;
          });
          break;
        }
        case 'account-activity': {
          runInAction(() => {
            this.accountActivityTimePeriod = data;
          });
          break;
        }
        case 'monitoring': {
          runInAction(() => {
            this.monitoringTimePeriod = data;
          });
          break;
        }
        case 'data': {
          runInAction(() => {
            this.transactionDataTimePeriod = data;
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  );

  setFilters = action(
    (
      data: PageFilters,
      type: 'dashboard' | 'account-activity' | 'monitoring' | 'data' | 'questionnaires' | 'tasks'
    ) => {
      switch (type) {
        case 'tasks': {
          runInAction(() => {
            this.tasksFilters = data;
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  );

  getTimePeriod = (type: string) => {
    switch (type) {
      case 'dashboard': {
        return this.dashboardTimePeriod;
      }
      case 'account-activity': {
        return this.accountActivityTimePeriod;
      }
      case 'monitoring': {
        return this.monitoringTimePeriod;
      }
      case 'data': {
        return this.transactionDataTimePeriod;
      }
      case 'tasks': {
        return this.tasksFilters;
      }
      default: {
        return;
      }
    }
  };

  clearStore = action(() => {
    this.dashboardTimePeriod = {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: ''
    };
    this.accountActivityTimePeriod = {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: ''
    };
    this.monitoringTimePeriod = {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: ''
    };
    this.transactionDataTimePeriod = {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      value: ''
    };
    this.tasksFilters = {
      dateRange: { start: '', end: '' },
      timeRange: { start: '', end: '' },
      selectedFilters: [],
      searchTerm: '',
      value: ''
    };
    this.accountRelationshipManagerFilters = {
      selectedAccountFilters: []
    };
  });
}

let store: AppViewStateStore | undefined;

export function getAppViewStateStore(): AppViewStateStore {
  if (!store) {
    store = new AppViewStateStore();
  }

  return store;
}
