import {
  CommentType,
  DeliveryMethod,
  Deposit,
  DueDiligenceStatus,
  IANATimezones,
  MinifiedDispensary,
  OrganizationRoleResolver,
  OrganizationType,
  User
} from '@gcv/shared';
import { inject, injectable } from 'inversify';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, observe, runInAction } from 'mobx';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CommentStore } from 'stores/CommentStore';
import { FiBankStore } from 'stores/FiBankStore';
import { FiDispensaryStore } from 'stores/FiDispensaryStore';
import { SnackbarSeverity, SnackbarStore } from 'stores/SnackBarStore';
import { UserStore } from 'stores/UserStore';
import { FilterListChild, FilterListItem, Row } from 'ui';
import { getDateRange } from 'util/dateRange.util';
import { formatMoney } from 'util/format.util';
import { DepositRow, FiDepositsRepo, DepositTableExtended, PM } from './deposits.repo';

export interface VM {
  bankId: () => string;
  bankTransportVendors: {
    id: string;
    name: string;
    favorite: boolean;
    active: boolean;
  }[];
  canUserAccessDeposit: boolean;
  currentDeposit: Deposit;
  currentDepositRow: DepositRow;
  currentDispensaryUsers: User[];
  defaultDeposits: DepositTableExtended[];
  depositRows: Row<DepositRow>[];
  deposits: DepositTableExtended[];
  depositsFilterBy: string;
  dispensaries: MinifiedDispensary[];
  editOpen: boolean;
  filterDispensariesWith: FilterListChild[];
  filterDispensaryOptions: FilterListChild[];
  filterDispensaryToggle: boolean;
  filterOptions: FilterListItem[];
  filterToggle: boolean;
  filterWith: FilterListChild[];
  filteredDeposits: Deposit[];
  isCurrentDepositLoading: boolean;
  isDeletingDeposits: boolean;
  isLoading: boolean;
  reconcileOpen: boolean;
  selectedRows: string[];
  showDeleteModal: boolean;
  showDepositDrawer: boolean;
  timezone: () => IANATimezones;
  userCanUpdateDepositStatus: () => boolean;
}

@injectable()
export class FiDepositsPresenter {
  @inject(OrganizationRoleResolver)
  private resolver: OrganizationRoleResolver;

  @inject(FiDepositsRepo)
  private repo: FiDepositsRepo;

  @inject(FiDispensaryStore)
  private dispensaryStore: FiDispensaryStore;

  @inject(UserStore)
  private userStore: UserStore;

  @inject(SnackbarStore)
  private snackbarStore: SnackbarStore;

  @inject(FiBankStore)
  private bankStore: FiBankStore;

  @inject(CommentStore)
  private commentStore: CommentStore;

  constructor() {
    makeAutoObservable(this);
  }

  get bankId() {
    return this.bankStore.bank.id;
  }

  get timezone() {
    return this.bankStore.bank.iana_timezone;
  }

  get userCanUpdateDepositStatus() {
    return this.resolver.userCanDoAction(this.bankStore.bank.groups, this.userStore.user, 'deposit_approve');
  }

  viewModel: VM = {
    bankId: () => this.bankId,
    bankTransportVendors: [],
    canUserAccessDeposit: false,
    currentDeposit: {} as Deposit,
    currentDepositRow: {} as DepositRow,
    currentDispensaryUsers: [],
    defaultDeposits: [],
    depositRows: [],
    deposits: [],
    depositsFilterBy: '',
    dispensaries: [],
    editOpen: false,
    filterDispensariesWith: [],
    filterDispensaryOptions: [],
    filterDispensaryToggle: false,
    filterOptions: [],
    filterToggle: false,
    filterWith: [],
    filteredDeposits: [],
    isCurrentDepositLoading: false,
    isDeletingDeposits: false,
    isLoading: true,
    reconcileOpen: false,
    selectedRows: [],
    showDeleteModal: false,
    showDepositDrawer: false,
    timezone: () => this.timezone,
    userCanUpdateDepositStatus: () => this.userCanUpdateDepositStatus
  };

  private updateViewModel = action((viewModel: Partial<VM>) => {
    this.viewModel = { ...this.viewModel, ...viewModel };
  });

  load = action(async () => {
    observe(this.repo, 'programmersModel', (obj) => {
      const programmersModel = obj.newValue as PM;

      this.updateViewModel({
        currentDeposit: programmersModel.currentDeposit,
        currentDispensaryUsers: programmersModel.currentDispensaryUsers,
        defaultDeposits: programmersModel.defaultDeposits,
        depositRows: programmersModel.depositRows,
        deposits: programmersModel.deposits,
        dispensaries: programmersModel.dispensaries,
        isCurrentDepositLoading: programmersModel.isCurrentDepositLoading,
        isDeletingDeposits: programmersModel.isDeletingDeposits,
        isLoading: programmersModel.isLoading
      });
    });

    this.updateViewModel({ isLoading: true });

    await this.repo.load();

    this.setPermission();
    this.setDispensaries(this.dispensaryStore.dispensaries);
    this.setFilterOptions();

    await this.applyDefaultFilters();
  });

  getStaffNameById = (userId: string) => this.bankStore.getStaffNameById(userId);

  getDepositById = action(async (id: string) => {
    await this.repo.getDepositById(id);
  });

  editDepositStatus = action(async (status: string) => {
    await this.repo.editDepositStatus(status);
  });

  reconcileDeposit = action(
    async (
      reconcileRecord: {
        depositAmount: number;
        mot: string;
        dateRecieved: string;
        datePosted: string;
        description: string;
      },
      mots: DeliveryMethod[]
    ) => {
      await this.repo.reconcileDeposit(reconcileRecord, mots);
    }
  );

  deleteDeposit = action(async (depositIds: string[]) => {
    await this.repo.deleteDeposit(depositIds);
    await this.setFilter(true);
  });

  setPermission = action(() => {
    this.updateViewModel({
      canUserAccessDeposit: this.resolver.userCanDoAction(
        this.bankStore.bank.groups,
        this.userStore.user,
        'deposit_delete'
      )
    });
  });

  setDispensaryFilter = action(async (filterTerms: FilterListChild[], forceReload: boolean) => {
    this.updateViewModel({ filterDispensariesWith: filterTerms });
    await this.setFilter(forceReload);
  });

  filterTableWithDispIds = action((depositsTable: DepositTableExtended[], dispIds: string[]) => {
    let filteredTable = depositsTable.filter((row) => dispIds.includes(row.dispensary_id));
    if (dispIds.length === 0) filteredTable = depositsTable;
    return filteredTable;
  });

  makeRows = (filteredTable: DepositTableExtended[]) => {
    return filteredTable.map((d: DepositTableExtended) => {
      return {
        id: d.deposit_id ? d.deposit_id : '',
        data: {
          id: d.deposit_id ? d.deposit_id : '',
          dispensary_name: d.dispensary_name,
          dispensary_id: d.dispensary_id,
          deposit_amount: d.final_deposit,
          method_of_transportation: d.delMethod ? d.delMethod.name : '',
          expected_arrival_date: d.expected_arrival_date,
          arrived_date: d.arrived_date,
          status: d.status
        }
      };
    });
  };

  setFilter = action(async (forceReload: boolean) => {
    if (!this.viewModel.filterWith.length) {
      return;
    }

    const filterTerms = this.viewModel.filterWith.concat(this.viewModel.filterDispensariesWith);

    this.updateViewModel({ isLoading: true });
    let tableData = [...this.viewModel.defaultDeposits];

    const timeStatusFilterTerms = filterTerms.filter((term) => term.parentValue === 'time_status');

    if (timeStatusFilterTerms.length) {
      let depositTables: DepositTableExtended[][] = [];
      const deposits: DepositTableExtended[] = [];

      if (!this.viewModel.defaultDeposits || forceReload) {
        const getTablePromises: any = [];

        timeStatusFilterTerms.forEach((timeStatusTerm) => {
          // make request for table data
          if (timeStatusTerm.selectValue) {
            getTablePromises.push(this.repo.getDepositsTable(timeStatusTerm));
          }
        });

        depositTables = await Promise.all(getTablePromises);
        depositTables.forEach((table) => {
          table.forEach((deposit) => {
            deposits.push(deposit);
          });
        });

        if (!this.viewModel.defaultDeposits.length) {
          this.repo.updateProgrammersModel({ defaultDeposits: deposits });
        }
      }

      const addedDeposits: string[] = [];
      const dedupedDeposits: DepositTableExtended[] = [];

      deposits.forEach((deposit) => {
        if (!addedDeposits.includes(deposit.deposit_id)) {
          addedDeposits.push(deposit.deposit_id);
          dedupedDeposits.push(deposit);
        }
      });

      tableData = dedupedDeposits;
    }

    // take the remaining table data either the response of the time status or the original
    // filter by just dispensary ids
    // filter terms combines disp ids and the other ones
    const dispIds = this.viewModel.filterDispensariesWith
      .filter((term) => !term.parentValue)
      .map((filterTerm) => filterTerm.value);

    tableData = this.filterTableWithDispIds(tableData, dispIds);

    // filter by status
    const statuses = filterTerms
      .filter((term) => term.parentValue === 'status')
      .map((filterTerm) => filterTerm.value);

    if (statuses.length) {
      tableData = tableData.filter((deposit) => {
        return statuses.includes(deposit.status);
      });
    }

    // filter by mot
    const mots = filterTerms
      .filter((term) => term.parentValue === 'mot')
      .map((filterTerm) => filterTerm.value);

    if (mots.length) {
      const allMots = mots
        .map((m) => {
          if (m !== 'VENDOR_AND_USER_MOT') {
            return m;
          } else {
            return this.getNonBankDeliveryOptionIds();
          }
        })
        .flat();

      tableData = tableData.filter((deposit) => allMots.includes(deposit.delMethod.id));
    }

    runInAction(() => {
      this.repo.updateProgrammersModel({
        deposits: tableData,
        depositRows: this.makeRows(tableData),
        isLoading: false
      });
    });
  });

  getDeliveryOptions = () => {
    // Helper method.
    const bankServices =
      this.bankStore.bank.transportVendors?.map((mot) => {
        return { label: mot.name, value: mot.id };
      }) || [];

    return [...bankServices, { label: 'Other Methods', value: 'VENDOR_AND_USER_MOT' }];
  };

  getNonBankDeliveryOptionIds = () => {
    const dispensaries = this.viewModel.dispensaries;
    const nonBankOptionsIds = dispensaries
      .map((d) => {
        if (d.methodOfTransportation === undefined) {
          return [];
        } else {
          const users = d.methodOfTransportation.user.flatMap((u) => u.id);
          const vendors = d.methodOfTransportation.vendor.flatMap((v) => v.id);

          return [...users, ...vendors];
        }
      })
      .flat();

    return [...new Set(nonBankOptionsIds)];
  };

  setFilterOptions = action(() => {
    const filterList = [
      {
        label: 'Status',
        value: 'status',
        children: [
          {
            label: 'Pending',
            value: 'pending',
            parentValue: 'status'
          },
          {
            label: 'Under Review',
            value: 'under_review',
            parentValue: 'status'
          },
          {
            label: 'Reconciled',
            value: 'accepted',
            parentValue: 'status'
          }
        ]
      },
      {
        label: 'Method of Transportation',
        value: 'mot',
        children: this.getDeliveryOptions()
      },
      {
        label: 'Time Status',
        value: 'time_status',
        children: [
          {
            label: 'Created',
            value: 'date_created',
            selectValue: {
              dateRange: { start: '', end: '' },
              timeRange: { start: '', end: '' },
              value: 'last30Days'
            },
            parentValue: 'time_status'
          },
          {
            label: 'Planned Arrival',
            value: 'expected_arrival_date',
            selectValue: {
              dateRange: { start: '', end: '' },
              timeRange: { start: '', end: '' },
              value: 'last30Days'
            },
            parentValue: 'time_status'
          },
          {
            label: 'Arrived',
            value: 'arrived_date',
            selectValue: {
              dateRange: { start: '', end: '' },
              timeRange: { start: '', end: '' },
              value: 'last30Days'
            },
            parentValue: 'time_status'
          }
        ]
      }
    ];
    this.updateViewModel({ filterOptions: filterList });
  });

  applyDefaultFilters = action(async () => {
    const getStatusDefaults = () => {
      const statusDefaults = this.viewModel.filterOptions
        .filter((option) => option.value === 'status')
        .flatMap((option) => option.children)
        .filter((child) => child.value === 'pending' || child.value === 'under_review');

      statusDefaults.forEach((option) => (option.selected = true));

      return statusDefaults;
    };

    const getDateDefaults = () => {
      const dateDefaults = this.viewModel.filterOptions
        .filter((option) => option.value === 'time_status')
        .flatMap((option) => option.children)
        .filter((options) => options.value === 'date_created');

      const timeRange = getDateRange('last30Days', this.bankStore.bank.iana_timezone);

      dateDefaults.forEach((option) => {
        option.selected = true;
        const d = option as {
          selectValue: {
            timeRange: { start: string; end: string };
            dateRange: { start: string; end: string };
            value: string;
          };
        };

        d.selectValue.timeRange = { ...timeRange };
        d.selectValue.dateRange = { ...timeRange };
        d.selectValue.value = 'last30Days';
      });

      return dateDefaults;
    };

    await runInAction(async () => {
      const filterWith = [...getStatusDefaults(), ...getDateDefaults()];

      this.updateViewModel({ filterWith: filterWith });
      await this.setDispensaryFilter(filterWith, true);
    });
  });

  toggleDispensaryFilter = action(() => {
    this.updateViewModel({ filterDispensaryToggle: !this.viewModel.filterDispensaryToggle });
  });

  applyDispensaryFilter = action(async (selected: FilterListChild[], newFilterList: FilterListChild[]) => {
    this.toggleDispensaryFilter();
    await this.setDispensaryFilter(selected, true);
  });

  toggleFilter = action(() => {
    this.updateViewModel({ filterToggle: !this.viewModel.filterToggle });
  });

  applyFilter = action(async (selected: FilterListChild[], newFilterList: FilterListChild[]) => {
    this.toggleFilter();
    this.updateViewModel({ filterWith: selected });
    await this.setFilter(true);
  });

  openDeposit = async (id: string, depositRow: DepositRow, openComments?: boolean) => {
    const rows = [...this.viewModel.depositRows];
    const deposits = [...this.viewModel.deposits];

    this.updateViewModel({ showDepositDrawer: true, isLoading: true });

    await this.getDepositById(id);

    this.updateViewModel({ currentDepositRow: depositRow });

    this.repo.updateProgrammersModel({
      depositRows: rows,
      deposits: deposits,
      isLoading: false
    });

    this.commentStore.setCurrentPost({
      title: `${depositRow.dispensary_name} - ${formatMoney(depositRow.deposit_amount)} Deposit`,
      type: CommentType.DEPOSIT,
      idComponents: {
        depositId: depositRow.id,
        crbId: depositRow.dispensary_id,
        fiId: this.bankId
      }
    });

    if (openComments) {
      this.commentStore.openComments(openComments);
    }
  };

  onCloseDeposit = () => {
    this.updateViewModel({ showDepositDrawer: false });
    this.commentStore.setCurrentPost(null);
  };

  onEditOpen = (form: UseFormReturn<FieldValues, any>) => {
    form.setValue('status', this.viewModel.currentDeposit.status);
    this.updateViewModel({ editOpen: true });
  };

  editClose = () => {
    this.updateViewModel({ editOpen: false });
  };

  onEdit = async (form: UseFormReturn<FieldValues, any>) => {
    this.updateViewModel({ editOpen: false });
    if (form.getValues('status') === this.viewModel.currentDeposit.status) return;
    await this.editDepositStatus(form.getValues('status'));
  };

  onReconcileOpen = () => {
    this.updateViewModel({ reconcileOpen: true, showDepositDrawer: false });
  };

  reconcileClose = () => {
    this.updateViewModel({ reconcileOpen: false });
  };

  onReconcile = async (
    formVals: {
      depositAmount: number;
      mot: string;
      dateRecieved: string;
      datePosted: string;
      description: string;
    },
    mots: DeliveryMethod[]
  ) => {
    this.updateViewModel({ reconcileOpen: false });
    await this.reconcileDeposit(formVals, mots);
  };

  setSelectedRows = (selectedRows: string[]) => {
    this.updateViewModel({ selectedRows: selectedRows });
  };

  setShowDeleteModal = (show: boolean) => {
    this.updateViewModel({ showDeleteModal: show });
  };

  getUser = (userId: string) => {
    const foundUser = this.viewModel.currentDispensaryUsers.find((user) => user.id === userId);
    const user = foundUser ? `${foundUser.firstName} ${foundUser.lastName}` : 'Unknown';

    return user;
  };

  resetDispensaryFilterOptions = action((dispensaries: MinifiedDispensary[]) => {
    const filteredDispensaries = dispensaries.filter(
      (d) => d.assigned_onboarding_template.status === DueDiligenceStatus.BANK_APPROVED
    );
    const dispensaryOptions = filteredDispensaries.map((dispensary) => {
      return {
        value: dispensary.id || '',
        label: dispensary.name || ''
      };
    });

    this.updateViewModel({ filterDispensaryOptions: dispensaryOptions });
  });

  setDispensaries = action((dispensaries: MinifiedDispensary[]) => {
    try {
      this.resetDispensaryFilterOptions(dispensaries);
      this.updateViewModel({ dispensaries: dispensaries });
    } catch (e) {
      this.snackbarStore.showSnackbar(
        SnackbarSeverity.Error,
        'There was an issue loading page data. Contact support for additional help.'
      );
    }
  });

  //called inside reconcile dialog
  //uses current deposit to determine which crb to get mots from
  getMots = () => {
    //favorite property has no impact on the reconcile deposits functionality
    //need to get a BankDispensay object for specific dispensary favorites. this api call has been omitted
    //dispensary specific favorite mot id lives on BankDispensary.favorite_transport_vendor_id;
    //this favorite here will be the bank global favorite that can be overriden by a dispensary specific favorite
    const bankMots = this.bankStore.bank.transportVendors
      .filter((mot) => mot.active)
      .map((mot) => {
        return {
          ...mot,
          active: mot.active === true,
          favorite: mot.favorite === true,
          type: 'bank'
        };
      });

    //archived accounts and MUO's can have deposits, do exhaustive search for dispensary by id
    const depositDispensary = this.dispensaryStore.findDispensary(
      this.viewModel.currentDeposit.dispensary_id
    );

    //will hold either one dispensary or an array of child dispensaries if the deposit is for an MUO
    const dispensaries: MinifiedDispensary[] = [];
    if (depositDispensary) {
      if (depositDispensary?.orgType === OrganizationType.DISPENSARY) {
        dispensaries.push(depositDispensary);
      } else if (depositDispensary?.orgType === OrganizationType.MUO) {
        const childDispensaries = [
          ...this.dispensaryStore.dispensaries,
          ...this.dispensaryStore.dispensariesArchived
        ].filter((dispensary) => {
          dispensary.muo_id === depositDispensary.id;
        });
        dispensaries.push(...childDispensaries);
      }
    }

    //will loop through an array of either one dispensary or multiple MUO children for user and vendor MOTs
    const allUserMots: DeliveryMethod[] = [];
    const allVendorMots: DeliveryMethod[] = [];

    dispensaries.forEach((dispensary) => {
      //get user mots for dispensary
      const userMots = dispensary.methodOfTransportation?.user
        ? dispensary.methodOfTransportation.user
            .filter((mot) => mot.active)
            .map((mot) => {
              return {
                ...mot,
                type: 'user'
              };
            })
        : [];
      //get vendor mots for dispensary
      const vendorMots = dispensary.methodOfTransportation?.vendor
        ? dispensary.methodOfTransportation.vendor
            .filter((mot) => mot.active)
            .map((mot) => {
              return {
                ...mot,
                type: 'vendor'
              };
            })
        : [];

      allUserMots.push(...userMots);
      allVendorMots.push(...vendorMots);
    });

    return [...bankMots, ...allUserMots, ...allVendorMots];
  };
}
