import { ReactComponent as CommentIcon } from 'assets/images/external-comment-icon.svg';
import { useComponent } from 'hooks';
import { useInjection } from 'ioc';
import * as React from 'react';
import { Button, Container, ContainerItem, Dialog, Header, Text } from 'ui';
import { CommentsPresenter } from '../comments.presenter';

const POPOVER_CLASS_NAME = 'archive-unarchive-popover';

export const CommentModalExternalConfirmation: React.FC = useComponent(() => {
  const presenter = useInjection(CommentsPresenter);

  return (
    <Dialog
      isOpen={presenter.viewModel.isExternalConfirmationModalOpen}
      handleClose={() => presenter.openExternalConfirmationModal(false)}
      title=""
      noActions
      className={POPOVER_CLASS_NAME}
      sx={{ zIndex: 100001 }}
    >
      <Container justify="center" padding="0">
        <ContainerItem width="100%" justify="center" padding="0">
          <CommentIcon style={{ width: '' }} />
        </ContainerItem>
        <ContainerItem width="100%" justify="center">
          <Header type="h3" content="Post External Comment" />
        </ContainerItem>
        <ContainerItem width="100%" justify="center">
          <Text
            type="body1"
            content="You are posting this comment externally, which means it can be seen by others outside of your organization. Please note that users will only get explicitly notified if they have been mentioned using the @ symbol."
          />
        </ContainerItem>
        <ContainerItem width="100%" justify="center">
          <Text type="body1" content="Are you sure you want to proceed?" />
        </ContainerItem>
        <ContainerItem align="center" justify="center" width="335px" margin="2rem 0 0 0" padding="0">
          <Button
            color="primary"
            label={'Confirm'}
            onClick={() => presenter.postComment()}
            fullWidth
            className={POPOVER_CLASS_NAME}
          ></Button>
        </ContainerItem>
        <ContainerItem align="center" justify="center" width="335px" margin=".25rem 0 0 0" padding="0">
          <Button
            color="default-text"
            label={'Cancel'}
            onClick={() => presenter.openExternalConfirmationModal(false)}
            className={POPOVER_CLASS_NAME}
            fullWidth
          ></Button>
        </ContainerItem>
      </Container>
    </Dialog>
  );
});
