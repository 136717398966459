import { Box, Table as MaterialTable, TableContainer as MaterialTableContainer } from '@mui/material';
import * as React from 'react';
import { Container, TableBody, TableFooter, TableHeader, TableHeaderCheckboxCell, ZeroState } from 'ui';
import palette from 'ui/theme/palette';
import { formatCurrencyNumberFromString } from 'util/numbers.util';

function numericComparator(a: number, b: number) {
  if (b === a) {
    return 0;
  }

  if (b < a || typeof b === 'undefined') {
    return -1;
  }

  if (b > a || typeof a === 'undefined') {
    return 1;
  }

  return 0;
}

function stringComparator(a: string, b: string) {
  if (a === b) {
    return 0;
  }

  if (b?.toLowerCase() < a?.toLowerCase() || typeof b === 'undefined') {
    return -1;
  }

  if (b?.toLowerCase() > a?.toLowerCase() || typeof a === 'undefined') {
    return 1;
  }

  return 0;
}

function timeComparator(a: string, b: string) {
  let first = '';
  let second = '';

  if (first.indexOf('PM')) {
    const hour = Number(a.slice(0, 2)) + 12;
    first = `${hour}${a.slice(2)}`;
  }

  if (second.indexOf('PM')) {
    const hour = Number(b.slice(0, 2)) + 12;
    second = `${hour}${b.slice(2)}`;
  }

  if (first === second) {
    return 0;
  }

  if (second < first || typeof second === 'undefined') {
    return -1;
  }

  if (second > first || typeof first === 'undefined') {
    return 1;
  }

  return 0;
}

function descendingComparator<T>(a: Row<T>, b: Row<T>, orderBy: keyof T) {
  if (typeof a.data[orderBy] !== typeof b.data[orderBy]) {
    const first = a.data[orderBy] as unknown as string | number;
    const second = b.data[orderBy] as unknown as string | number;

    return stringComparator(first?.toString(), second?.toString());
  } else if (typeof a.data[orderBy] === 'string') {
    const first = a.data[orderBy] as unknown as string;
    const second = b.data[orderBy] as unknown as string;

    if (first.indexOf('$') > -1) {
      return numericComparator(formatCurrencyNumberFromString(first), formatCurrencyNumberFromString(second));
    }

    if (first.indexOf('AM') > -1 || first.indexOf('PM') > -1) {
      return timeComparator(first, second);
    }

    return stringComparator(first, second);
  } else {
    const first = a.data[orderBy] as unknown as number;
    const second = b.data[orderBy] as unknown as number;

    return numericComparator(first, second);
  }
}

export type Order = 'asc' | 'desc';

function getComparator<T>(order: Order, orderBy: keyof T): (a: Row<T>, b: Row<T>) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}
export interface Column<T> {
  id: keyof T;
  label: string;
  format?: (val: T) => string | JSX.Element;
  disablePadding?: boolean;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  widthEven?: boolean;
  widthPercent?: number;
  sortFunction?: (a: Row<T>, b: Row<T>) => number;
  noSort?: boolean;
  tooltip?: string;
}

export interface Row<T> {
  id: string;
  data: T;
  disableSelect?: boolean;
  selectTooltip?: string;
  heavyBottomBorder?: boolean;
  heavyTopBorder?: boolean;
  highlightBackground?: boolean;
  clickable?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
}

export interface Props<T> {
  columns: Column<T>[];
  rows: Row<T>[];
  maxHeight?: string;
  defaultSort: keyof T;
  defaultSortDirection?: Order;
  noDataComponent?: React.FC<unknown>;
  noPagination?: boolean;
  clickable?: boolean;
  defaultRowCount?: number;
  updateSelected?: string[];
  updateSelectedRow?: string;
  returnSelection?: (selectedRows: string[]) => void;
  selectable?: boolean;
  defaultSelected?: string[];
  onClick?: (id: string, data: T) => void;
  dataCy?: string;
  actions?: React.ReactNode;
  singleSelectable?: boolean;
  tableLayout?: 'auto' | 'fixed' | 'initial' | 'inherit' | 'unset';
  showFilterZeroState?: boolean;
  minWidth?: string | number;
  footerRows?: JSX.Element;
}

const FilterZeroState = () => {
  return (
    <ZeroState
      title={'There are no results to show with the filters.'}
      subTitle={'Please try changing your filters to show more records.'}
      icon="/img/empty-states/NoResults.svg"
      noBorder
    />
  );
};

export const Table = <T,>(props: Props<T>) => {
  const [order, setOrder] = React.useState<Order>(props.defaultSortDirection ?? 'desc');
  const [orderBy, setOrderBy] = React.useState<keyof T>(props.defaultSort);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRow, setSelectedRow] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  React.useEffect(() => {
    if (props.updateSelected) {
      setSelected(props.updateSelected);
    }
  }, [props.updateSelected]);

  React.useEffect(() => {
    if (props.updateSelectedRow !== undefined) {
      setSelectedRow(props.updateSelectedRow);
      setSelected(props.updateSelectedRow.length === 0 ? [] : [props.updateSelectedRow]);
    }
  }, [props.updateSelectedRow]);

  React.useEffect(() => {
    if (rows.length > 0) {
      if (page * rowsPerPage >= rows.length) {
        setPage(0);
      }
    }
    if (props.noPagination) {
      setRowsPerPage(props.rows.length);
    }
  }, [props.rows.length]);

  React.useEffect(() => {
    if (props.defaultSelected) {
      setSelected(props.defaultSelected);
      props.returnSelection && props.returnSelection(props.defaultSelected);
    }
  }, []);

  React.useEffect(() => {
    if (props.defaultRowCount) {
      setRowsPerPage(props.defaultRowCount);
    }
  }, [props.defaultRowCount]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRequestSortBySelected = (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === 'selected' && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy('selected' as keyof T);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && selected.length === 0) {
      const newSelecteds = props.rows.filter((row) => !row.disableSelect).map((n) => n.id);
      setSelected(newSelecteds);
      props.returnSelection && props.returnSelection(newSelecteds);
      return;
    }

    setSelected([]);
    props.returnSelection && props.returnSelection([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string, data: T) => {
    event.stopPropagation();
    if (props.clickable && props.onClick) {
      props.onClick(id, data);
    }
  };

  const handleSelect = (event: React.MouseEvent<unknown>, id: string) => {
    if (props.selectable) {
      const selectedIndex = selected.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      props.returnSelection && props.returnSelection(newSelected);
      setSelected(newSelected);
    }
  };

  const handleRadio = (value: string) => {
    if (props.singleSelectable) {
      props.returnSelection && props.returnSelection([value]);
      setSelected([value]);
      setSelectedRow(value);
    }
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  let rows = props.rows;
  const sortByColumn = props.columns.find((c) => c.id === orderBy);
  if (sortByColumn && sortByColumn.sortFunction) {
    rows = stableSort(props.rows, sortByColumn.sortFunction);
    rows = order === 'asc' ? rows : rows.reverse();
  } else {
    rows = stableSort(props.rows, getComparator(order, orderBy));
  }
  const rowsSortedBySelected =
    orderBy === 'selected'
      ? rows.sort((r: Row<T>) => (order === 'asc' ? (isSelected(r.id) ? 1 : -1) : isSelected(r.id) ? -1 : 1))
      : rows;
  const ZeroState = props.noDataComponent;

  if (props.showFilterZeroState) {
    return <FilterZeroState />;
  } else {
    return (
      <div data-cy={props.dataCy}>
        {props.rows.length < 1 && ZeroState ? (
          <ZeroState />
        ) : (
          <>
            {selected.length > 0 && props.actions && (
              <Box
                sx={{
                  width: '100%',
                  height: '40px',
                  bgcolor: 'background.secondary',
                  borderBottom: (theme) => `0.016rem solid ${theme.palette.divider}`,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <TableHeaderCheckboxCell
                  selected={selected}
                  rows={props.rows}
                  orderBy={orderBy}
                  order={order}
                  handleSelectAllClick={handleSelectAllClick}
                  handleSort={handleRequestSortBySelected}
                  sx={{ borderBottom: 'none' }}
                />
                <Container padding="0 0 0 18px">{props.actions}</Container>
              </Box>
            )}
            <MaterialTableContainer
              sx={{ maxHeight: props.maxHeight ? props.maxHeight : undefined, overflowY: 'auto' }}
            >
              <MaterialTable
                sx={{
                  height: props.maxHeight ? 'max-content' : undefined,
                  tableLayout: props.tableLayout ?? 'fixed',
                  minWidth: props.minWidth ? props.minWidth : '750px'
                }}
              >
                {selected.length && props.actions ? null : (
                  <TableHeader
                    selectable={props.selectable || false}
                    singleSelectable={props.singleSelectable || false}
                    columns={props.columns}
                    selectedRows={selected}
                    rows={rowsSortedBySelected}
                    orderBy={orderBy}
                    order={order}
                    handleSelectAllClick={handleSelectAllClick}
                    handleRequestSort={handleRequestSort}
                    handleRequestSortBySelected={handleRequestSortBySelected}
                  />
                )}
                <TableBody
                  rows={rowsSortedBySelected}
                  selectable={props.selectable || false}
                  singleSelectable={props.singleSelectable || false}
                  clickable={props.clickable || false}
                  columns={props.columns}
                  handleSelect={handleSelect}
                  handleClick={handleClick}
                  handleRadio={handleRadio}
                  selected={selected}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  selectedRow={selectedRow}
                ></TableBody>
              </MaterialTable>
            </MaterialTableContainer>
            {!props.noPagination ? (
              <TableFooter
                minWidth={props.minWidth}
                rows={props.rows}
                footerRows={props.footerRows}
                defaultRowCount={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
              />
            ) : props.footerRows ? (
              <Box
                sx={{
                  backgroundColor: palette.background.secondary,
                  borderTop: `1px solid ${palette.divider}`
                }}
              >
                {props.footerRows}
              </Box>
            ) : null}
          </>
        )}
      </div>
    );
  }
};
