import {
  BankCustomFields,
  CrbServiceProvider,
  CreateDocumentsRequest,
  CustomRequirementResult,
  DocumentUpload,
  DueDiligenceDocument,
  OnboardingDocumentRequirement,
  TemplateResponse,
  TemplateResultResponse
} from '@gcv/shared';
import { DispensariesApi, DocumentsApi, TemplateApi } from 'api';
import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { CrbDispensaryStore } from 'stores/CrbDispensaryStore';
import { SnackbarStore } from 'stores/SnackBarStore';
import { getObjectMap } from 'util/objectUtils';
import { uploadOrgDocumentToS3 } from 'util/s3.util';

export interface PDPM {
  loadingAdditionalInfo: boolean;
  template: TemplateResponse | null;
  templateResult: TemplateResultResponse | null;
  details: CrbServiceProvider | null;
  docs: {
    [x: string]: {
      requirementId: string;
      files: File[];
    };
  };
  sharedRootDocs: {
    requirement: OnboardingDocumentRequirement;
    ddDocuments: DueDiligenceDocument[];
    documents: DocumentUpload[];
  }[];
}
@injectable()
export class ProviderDetailsRepo {
  @inject(DispensariesApi) private dispensariesApi: DispensariesApi;
  @inject(TemplateApi) private templateApi: TemplateApi;
  @inject(DocumentsApi) private documentsApi: DocumentsApi;
  @inject(CrbDispensaryStore) private crbDispensaryStore: CrbDispensaryStore;
  @inject(SnackbarStore) private snackbarStore: SnackbarStore;

  constructor() {
    makeAutoObservable(this);
  }

  programmersModel: PDPM = {
    loadingAdditionalInfo: true,
    template: null,
    templateResult: null,
    details: null,
    docs: {},
    sharedRootDocs: []
  };

  updateProgrammersModel = action((programmersModel: Partial<PDPM>) => {
    this.programmersModel = { ...this.programmersModel, ...programmersModel };
  });

  load = action(async (providerId: string, details: CrbServiceProvider | null) => {
    this.updateProgrammersModel({ loadingAdditionalInfo: true });
    if (!details) {
      const providers = await this.fetchDispensaryServiceProviders();
      details = providers[providerId];
    }
    const templateResult = await this.fetchOnboardingTemplateResult(
      details.templates.crb_id,
      details.templates.value.onboarding.template_id,
      details.templates.value.onboarding.template_result_id
    );
    const template = await this.fetchOnboardingTemplate(
      providerId,
      details.templates.value.onboarding.template_id,
      templateResult.template_version
    );

    const sharedRootDocs = await this.getSharedDcouments(details.templates.crb_id);

    this.updateProgrammersModel({
      template: template,
      templateResult: templateResult,
      details: details,
      loadingAdditionalInfo: false,
      docs: {},
      sharedRootDocs
    });
  });

  getSharedDcouments = action(
    async (
      crbId: string
    ): Promise<
      {
        requirement: OnboardingDocumentRequirement;
        ddDocuments: DueDiligenceDocument[];
        documents: DocumentUpload[];
      }[]
    > => {
      const sharedRootDocs = await this.dispensariesApi.getSharedRootDocuments(crbId);
      return sharedRootDocs;
    }
  );

  fetchOnboardingTemplate = action(async (id: string, templateId: string, templateVersion: string) => {
    try {
      return this.templateApi.getBankTemplate(id, templateId, templateVersion ?? 'latest');
    } catch (e) {
      console.log(e);
      throw new Error('There was an issue loading page data. Contact support for additional help.');
    }
  });

  fetchOnboardingTemplateResult = action(async (id: string, templateId: string, templateIdResult: string) => {
    try {
      return this.templateApi.getCrbTemplateResult(id, templateId, templateIdResult);
    } catch (e) {
      console.log(e);
      throw new Error('There was an issue loading page data. Contact support for additional help.');
    }
  });

  fetchDispensaryServiceProviders = action(async () => {
    try {
      return await this.dispensariesApi.getDispensaryServiceProviders(
        this.crbDispensaryStore.currentDispensary.id
      );
    } catch (error) {
      console.log(error);
      throw new Error('There was a problem fetching service providers');
    }
  });

  startOnboardingTemplate = action(async (dispensaryId: string, templateId: string) => {
    try {
      await this.templateApi.startCrbOnboardingTemplate(dispensaryId, templateId);
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not start onboarding template.');
    }
  });

  uploadOnboardingTemplateDocument = action(
    async (requirementId: string, fieldId: string, file: File): Promise<DocumentUpload | undefined> => {
      try {
        if (this.programmersModel.template) {
          const docId = await uploadOrgDocumentToS3(file, this.crbDispensaryStore.currentDispensary.id);
          const document = await this.documentsApi.putDocument(
            file.name,
            docId,
            this.crbDispensaryStore.currentDispensary.id
          );
          return document;
        }
      } catch (error) {
        this.snackbarStore.showErrorSnackbarMessage('Could not upload the file');
      }
    }
  );

  public saveAndCompleteOnboardingTemplate = action(async (customFieldValues: Record<string, any>) => {
    try {
      const updateRequirementPromises: Promise<CustomRequirementResult>[] = [];

      if (this.programmersModel.template && this.programmersModel.templateResult) {
        const requirementIds = Object.keys(this.programmersModel.template.custom_requirements);
        const updatedRequirementResponses: { [id: string]: CustomRequirementResult } = {};

        for (const sectionId of requirementIds) {
          const requirementFieldIds = this.programmersModel.template.custom_requirements[
            sectionId
          ].custom_section.fields.map((field) => field.id);

          const existingResponses = this.programmersModel.templateResult.custom_requirement_result.find(
            (item) => requirementIds.includes(item.id)
          )?.custom_fields.responses;

          const customFieldRequest = {
            responses: {
              ...existingResponses,
              ...Object.entries(customFieldValues)
                .filter((item) => requirementFieldIds.find((id: string) => id === item[0]))
                .reduce(
                  (acc, curr) => {
                    acc[curr[0]] = curr[1];

                    return acc;
                  },
                  {} as {
                    [field_key: string]: string;
                  }
                )
            }
          } as BankCustomFields;

          updateRequirementPromises.push(
            this.templateApi.updateCrbTemplateRequirement(
              this.crbDispensaryStore.currentDispensary.id,
              this.programmersModel.template.bank_id,
              this.programmersModel.template.template_id,
              sectionId,
              customFieldRequest
            )
          );
        }

        await Promise.all(updateRequirementPromises).then((results) => {
          for (const result of results) {
            updatedRequirementResponses[result.requirement_id] = result;
          }

          const bankId = this.programmersModel.template?.bank_id;
          const crbId = this.crbDispensaryStore.currentDispensary.id;
          const templateId = this.programmersModel.template?.template_id;

          if (bankId && crbId && templateId) {
            this.templateApi.crbFinishedUpdatingCustomFields(
              bankId,
              crbId,
              templateId,
              this.programmersModel.templateResult?.custom_requirement_result ?? []
            );
          }
        });

        return updatedRequirementResponses;
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not save the template response');
    }
  });

  deleteOnboardingTemplateDocument = action(async (file: DocumentUpload) => {
    try {
      if (this.programmersModel.template) {
        await this.documentsApi.deleteDocument(file.org_id, file.id);
      }
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage('Could not delete the file');
    }
  });

  getDocuments = action(async (id: string) => {
    try {
      const documents = await this.documentsApi.getDocuments(id);
      const documentsMap = getObjectMap(documents, 'id');
      return documentsMap;
    } catch (error) {
      this.snackbarStore.showErrorSnackbarMessage(
        'There was an issue loading page data. Contact support for additional help.'
      );
    }
  });

  addDocToOnboarding = action(
    async (requirementId: string, documents: CreateDocumentsRequest, docSharingFeaureFlag: boolean) => {
      try {
        if (this.programmersModel.template) {
          if (docSharingFeaureFlag) {
            const templateResultResponse =
              await this.templateApi.addOnboardingDocumentRequirementDocumentsCrbV2(
                this.crbDispensaryStore.currentDispensary.id,
                this.programmersModel.template?.template_id,
                requirementId,
                documents
              );
            return templateResultResponse;
          } else {
            const templateResultResponse =
              await this.templateApi.addOnboardingDocumentRequirementDocumentsCrb(
                this.crbDispensaryStore.currentDispensary.id,
                this.programmersModel.template?.template_id,
                requirementId,
                documents
              );
            return templateResultResponse;
          }
        }
      } catch (error) {
        this.snackbarStore.showErrorSnackbarMessage(
          'There was an issue loading page data. Contact support for additional help.'
        );
      }
    }
  );
  public setDocs = (data: {
    [x: string]: {
      requirementId: string;
      files: File[];
    };
  }) => {
    this.updateProgrammersModel({ docs: data });
  };
}
