import {
  CommentIdComponents,
  CommentType,
  GroupedTask,
  MinifiedTask,
  OrganizationType,
  SystemTaskData,
  TaskAssociation,
  TaskCategory,
  TaskInternalType,
  TaskStatus
} from '@gcv/shared';

export interface DecoratedTask {
  id: string;
  isGrouped?: boolean;
  groupKey: string;
  name: string;
  tasks: MinifiedTask[];
}

export interface TaskRow {
  account: string;
  assignee: string;
  associatedWith: string;
  completed: string;
  completedBy: string;
  created: string;
  createdBy: string;
  dueDate: string;
  id: string;
  taskName: string;
  createdTimestamp: number; // We anticipate sorting by this field, and including the time as well as the date.
  completedTimestamp?: number; // Ditto the above.
  groups: string[];
  status: TaskStatus;
  sentOn: string;
  frequency: string;
  type: 'task' | 'questionnaire';
}

export interface TaskDetails {
  account: string;
  assignee: string;
  associatedWith: string;
  comment_id_components?: CommentIdComponents;
  comment_type?: CommentType;
  completedBy: string;
  completedDate: string;
  completedNote: string;
  createdDate: string;
  createdBy: string;
  data?: SystemTaskData;
  description: string;
  dueDate: string;
  groupedTasks: MinifiedTask[];
  id: string;
  internalType: TaskInternalType | undefined;
  isGrouped?: boolean;
  name: string;
  status: TaskStatus;
  taskCategory: TaskCategory;
}

export interface TaskDrawerField {
  label: string;
  value: string;
  state: TaskStatus[];
  type: TaskCategory[];
  width: number;
  color?: string;
}

export const isGroupedTask = (task: MinifiedTask | GroupedTask | TaskDetails): task is GroupedTask => {
  return (task as GroupedTask).is_grouped || (task as TaskDetails).isGrouped;
};

export const hasSubtasks = (task: GroupedTask | TaskDetails) => {
  return (task as GroupedTask).tasks?.length > 1 || (task as TaskDetails).groupedTasks?.length > 1;
};

export const mockTaskList: MinifiedTask[] = [
  {
    id: '1',
    task_category: TaskCategory.USER,
    title: 'Task 1 - OPEN - USER',
    description: 'Task 1',
    process_date: '2023-03-10T20:15:58.984Z',
    date_created: '2023-03-10T20:15:59.005Z',
    status: TaskStatus.OPEN,
    associated_with: TaskAssociation.ACCOUNTS,
    assigned_org: {
      id: 'c9d8b57b-effd-4c24-a68c-3bac4c72faef',
      name: 'Ben Bank',
      orgType: OrganizationType.BANK
    },
    org: {
      id: 'c9d8b57b-effd-4c24-a68c-3bac4c72faef',
      name: 'Ben Bank',
      orgType: OrganizationType.BANK
    },
    assigned_users: [
      {
        id: 'e92ebfa0-142f-4c5c-93be-bf21286d8788',
        firstName: 'Ben',
        lastName: 'Burke'
      }
    ],
    org_id: '1'
  },
  {
    id: '2',
    task_category: TaskCategory.SYSTEM,
    title: 'Task 2 - OPEN - SYSTEM',
    description: 'Task 2',
    process_date: '2023-03-10T20:15:58.984Z',
    date_created: '2023-03-10T20:15:59.005Z',
    status: TaskStatus.OPEN,
    associated_with: TaskAssociation.SALES_DATA,
    assigned_org: {
      id: 'c9d8b57b-effd-4c24-a68c-3bac4c72faef',
      name: 'Ben Bank',
      orgType: OrganizationType.BANK
    },
    org: {
      id: 'c9d8b57b-effd-4c24-a68c-3bac4c72faef',
      name: 'Ben Bank',
      orgType: OrganizationType.BANK
    },
    assigned_users: [
      {
        id: 'e92ebfa0-142f-4c5c-93be-bf21286d8788',
        firstName: 'Ben',
        lastName: 'Burke'
      }
    ],
    org_id: '2'
  },
  {
    id: '3',
    task_category: TaskCategory.USER,
    title: 'Task 3 - COMPLETE - USER',
    description: 'Task 3',
    process_date: '2023-03-10T20:15:58.984Z',
    date_created: '2023-03-10T20:15:59.005Z',
    status: TaskStatus.COMPLETE,
    associated_with: TaskAssociation.DEPOSIT_RECORDS,
    assigned_org: {
      id: 'c9d8b57b-effd-4c24-a68c-3bac4c72faef',
      name: 'Ben Bank',
      orgType: OrganizationType.BANK
    },
    org: {
      id: 'c9d8b57b-effd-4c24-a68c-3bac4c72faef',
      name: 'Ben Bank',
      orgType: OrganizationType.BANK
    },
    assigned_users: [
      {
        id: 'e92ebfa0-142f-4c5c-93be-bf21286d8788',
        firstName: 'Ben',
        lastName: 'Burke'
      }
    ],
    org_id: '3'
  },
  {
    id: '4',
    task_category: TaskCategory.SYSTEM,
    title: 'Task 4 - COMPLETE - SYSTEM',
    description: 'Task 4',
    process_date: '2023-03-10T20:15:58.984Z',
    date_created: '2023-03-10T20:15:59.005Z',
    status: TaskStatus.COMPLETE,
    associated_with: TaskAssociation.ACCOUNTS,
    assigned_org: {
      id: 'c9d8b57b-effd-4c24-a68c-3bac4c72faef',
      name: 'Ben Bank',
      orgType: OrganizationType.BANK
    },
    org: {
      id: 'c9d8b57b-effd-4c24-a68c-3bac4c72faef',
      name: 'Ben Bank',
      orgType: OrganizationType.BANK
    },
    assigned_users: [
      {
        id: 'e92ebfa0-142f-4c5c-93be-bf21286d8788',
        firstName: 'Ben',
        lastName: 'Burke'
      }
    ],
    org_id: '4'
  }
];
